import jsencrypt from "jsencrypt";

const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCzrjWyZGR7kNdlnjDYptEB9mEc10NU53muUM/0vbzD8ivekX5zuJ6P7VrjzT7VIu1r9U9B2+xXzSF+2tinEzBpE8z/DAeL235ZmNUQJFIVGvrGUYs4q7nj21n4qNlwfbjpEH2kPkBG3jgAeEHMXj4tkaI5Nb/6Kr+yCZpaSn2U+wIDAQAB";

// 加密
export function encrypt(str) {
    let encrypt = new jsencrypt();
    encrypt.setPublicKey(publicKey);
    if (!str) {
        return encrypt.encrypt(null);
    }
    let code = "";
    let lt = str.match(/.{1,117}/g);
    lt.forEach(function (entry) {
        code += encrypt.encrypt(entry);
    });
    if (lt.length > 1) {
        return '^' + code;
    } else {
        return code;
    }
}