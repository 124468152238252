<template>
  <div>
    <el-dialog title="登录" width="350px" center :visible.sync="dialogFormVisible" :before-close="close">
      <el-row :gutter="15">
        <el-form ref="loginUser" :model="loginUser" :rules="rules" size="medium">
          <el-col :span="24">
            <el-form-item prop="username">
              <el-input v-model="loginUser.username" placeholder="请输入手机/邮箱/用户名"
                        prefix-icon='el-icon-user' :style="{width: '100%'}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="password">
              <el-input v-model="loginUser.password" placeholder="请输入密码" prefix-icon='el-icon-unlock'
                        type="password" :style="{width: '100%'}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="17">
            <el-form-item prop="captcha">
              <el-input v-model="loginUser.captcha" placeholder="请输入验证码" prefix-icon='el-icon-unlock'
                        :style="{width: '100%'}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <div v-html="str"
                 style="display: flex; height: 36px; overflow: hidden; width: 100%; justify-content: end;"
                 @click="createImg()"></div>
          </el-col>
          <el-col :span="16">
            <el-form-item prop="readAgree">
              <el-checkbox-group v-model="loginUser.readAgree">
                <el-checkbox name="readAgree">
                  <span style="color: #40a9ff">我已阅读并接受</span> <a
                    :href="this.member+'/member/sysreg/viewRegister.htm?isAudit=1&no_sitemesh'">用户协议</a>
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="text-align: right">
            <el-form-item>
              <a :href="this.member+'/member/user/findPassword.htm?no_sitemesh'" target="_blank" type="danger">
                忘记密码？
              </a>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="submitForm('loginUser')">登录</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {encrypt} from '@/utils/jsencrypt'

export default {
  name: "login",
  inheritAttrs: false,
  components: {},
  props: ['dialogFormVisible'],
  data() {
    return {
      newDialogFormVisible: this.dialogFormVisible,
      loginUser: {
        username: "",
        password: "",
        xstr: '',
        captcha: "",
        caKey: "",
        loginType: "0",
        checkCaFlag: "",
        agent: "",
        openid: "",
        unionid: "",
        errorMessage: "",
        loginFlag: 0,
        readAgree: [],
      },
      rules: {
        username: [{
          required: true,
          message: '请输入手机/邮箱/用户名',
          trigger: 'change'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'change'
        }],
        captcha: [{
          required: true,
          message: '请输入验证码',
          trigger: 'change'
        }, {
          min: 4,
          max: 4,
          trigger: 'blur',
          message: "验证码长度为4位"
        }], readAgree: [{
          type: 'array',
          required: true,
          message: '请阅读并接受用户协议',
          trigger: 'change'
        }],
      },
      str: "",
    }
  },
  computed: {},
  watch: {
    dialogFormVisible: {
      deep: true,    // 这句重要,持续监听
      handler(val) {
        this.newDialogFormVisible = val
      }
    }
  },
  created() {
    this.createImg();
  },
  mounted() {
  },
  methods: {
    close(done) {
      this.$emit('update:dialogFormVisible', false);
      this.$refs['loginUser'].resetFields();
    },
    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        that.loginUser.xstr =encrypt(that.loginUser.password);
        if (valid) {
          that.$http({
                url: that.member + "/member/login/executeCgzxLogin.htm",
                data: that.loginUser,
                method: 'post',
              }
          ).then((data) => {
            if (data.data.success == '1') {
              //登录成功，关闭模态框
              that.$emit('update:dialogFormVisible', false);
              that.cmsMessage({
                message: '登录成功！',
                type: 'success'
              });
            } else {
              //提示错误信息
              that.$refs[formName].fields[3].validateMessage = data.data.message;
              this.$refs[formName].fields[3].validateState = 'error';
              that.loginUser.loginFlag = data.data.loginFlag;
              that.createImg();
            }
          })
        } else {
          console.log('error submit!!');
        }
      });
    },
    createImg() {
      let that = this;
      let str = Math.floor(Math.random() * 10000);
      str = that.member + "/member/login/captcha.htm?rnd-" + str;
      this.str = "<img src='" + str + "' style='border-radius: 4px;'>";
    },

  }
}

</script>
<style>
.el-dialog__body {
  padding-bottom: 10px !important;
}
</style>
