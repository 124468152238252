<template>
  <div>
    <div id="zone-head">
      <zone-header></zone-header>
      <div class="cms-header-content">
        <div class="cms-container">
          <div class="logo-image left">
            <img :src="logoUrl" class="w-100 h-100" alt="">
          </div>
          <div class="left">

          </div>
        </div>
      </div>
    </div>
    <div id="zone-head-placeholder"></div>
<!--    <login :dialogFormVisible.sync="loginShow"  style="width: 100px"></login>-->
    <div class="width_width child_bg_1">
      <div class="width_w clearfix cms-container">
        <div class="now_add left clearfix">
          <router-link class="now_a left" v-if="dataObj.newVersionZone =='1'"
                       :to="{name:'specializedNewZone',query:{'companyId':headCompanyId,'id':headId,'headLogo':headLogo}}">
            企业首页
          </router-link>
          <router-link class="now_a left" v-else-if="dataObj.newVersionZone =='2'"
                       :to="{name:headId == '6802' ?'specializedZoneYx':'specializedNewZoneTwo',query:{'companyId':headCompanyId,'id':headId,'headLogo':headLogo}}">
            企业首页
          </router-link>
          <router-link class="now_a left" v-else
                       :to="{name:'zone',query:{'companyId':headCompanyId,'id':headId,'headLogo':headLogo}}">
            企业首页
          </router-link>
          <img class="now_arrow left" src="../../assets/image/pc/jiantou.svg"/>
          <router-link class="now_a left"
                       :to="{name:'zonePurchase',query:{'companyId':headCompanyId,'id':headId,'headLogo':headLogo}}">
            {{ buyerTitle }}
          </router-link>
        </div>
      </div>
      <span class="child_name">{{ buyerTitle }}</span>
    </div>
    <div class="cms-container notice-info">
<!--      <template v-if="notice.projectSource !== '20' && notice.projectSource !== '30'">-->
<!--        &lt;!&ndash;   登录后没有权限   &ndash;&gt;-->
<!--        <div v-if="(!isAllow) && userLoginFlag"-->
<!--             v-bind:style="{'position':'absolute','z-index': '200','margin-top': topHeight+'px','background': 'white','width': '100%','height':loginHeight,'opacity': '0.96'}">-->
<!--          <el-result icon="warning" title="您没有权限" subTitle="该项目为邀请项目，仅受邀单位可查看详细信息">-->
<!--          </el-result>-->
<!--        </div>-->
<!--        &lt;!&ndash;   没有登录   &ndash;&gt;-->
<!--        <div v-if="!userLoginFlag && !isVc "-->
<!--             v-bind:style="{'position':'absolute','z-index': '200','margin-top': topHeight+'px','background': 'white','width': '100%','height':loginHeight,'opacity': '0.96'}">-->
<!--          <el-result icon="warning" title="您还未登录" subTitle="请登录后进行查看详细信息">-->
<!--            <template slot="extra">-->
<!--              <el-button type="primary" size="medium" v-on:click="login()">登录</el-button>-->
<!--            </template>-->
<!--          </el-result>-->
<!--        </div>-->
<!--      </template>-->
      <div class="notice-left left">
        <div class="news_child" id="child_content">
          <div class="child_title">
            <span class="child_span">{{ notice.title }}</span>
            <div class="notice_box"><span class="notice_time">
              发布时间：{{this.$route.query.vc != null && this.$route.query.vc != undefined && this.$route.query.vc !="" && this.$route.query.vc !="0"?  notice.publishTime: notice.createTime}}
              <a href="javascript:void(0)" style="color: #277eff; padding-left: 10px" v-if="showJoinNow" @click="joinNow(notice)">立即参与</a>
            </span></div>
          </div>
          <div class="child_content" v-html="notice.content">
<!--          <div class="child_content" v-html="notice.content" :style="{'height': showHeight,'overflow':'hidden'}">-->
          </div>
        </div>
      </div>
    </div>
    <specialized-new-footer style="margin-top: 15px;" :configForm="configForm" :company="company"></specialized-new-footer>
    <technical-support></technical-support>
  </div>
</template>

<script>
import ZoneHeader from "./zone-header";
import SpecializedNewFooter from "../specialized-new-zone/specialized-new-footer";
import TechnicalSupport from "../../components/commoon/component/technical-support";
import Login from "../../components/commoon/component/login";
import {getUserInfo} from "../../api/commoon/user";

export default {
  name: "purchaseDetails",
  components: {/*Login,*/ TechnicalSupport, SpecializedNewFooter, ZoneHeader},
  data() {
    return {
      notice: {},
      notices: {},
      headCompanyId: "",
      headId: "",
      headLogo: "",
      logoUrl: "",
      dataObj: {},
      userLoginFlag: false,
      timer:null,
      buyerTitle:"采购公告",
      loginHeight: "",
      topHeight: 400,
      isAllow: false,
      showHeight:"700px",
      company:{},
      configForm: {},
      loginShow:false,
      isVc:false,
    }
  },
  computed: {
    showJoinNow() {
      const { bidOpenTime, projectSource, type, formType } = this.notice
      if (type !== 'BID_BULLETIN' && type !== 'CHANGE_BULLETIN') {
        return false
      }
      if(formType !== '10') {
        return false
      }
      if (bidOpenTime && projectSource && projectSource !== '15') {
        return new Date().getTime() < new Date(bidOpenTime).getTime()
      }
      return false
    }
  },
  methods: {
    joinNow(notice) {
      const { origin, host } = window.location
      const projectSource = notice.projectSource
      const isProd = host === 'www.holdcg.com'
      if (projectSource === '20') {
        window.location.href = (isProd ? 'https://www.holdcg.com':'http://dev6.ebidding.net.cn:18300') + '/ebidding/#/login'
      } else if (projectSource === '30') {
        window.location.href = (isProd ? 'http://hngt.zcjb.com.cn' : 'http://hngt.dev.ebidding.net.cn:16800') + '/ebidding/#/login'
      } else {
        window.location.href = origin + '/member/login/executeLogin.htm'
      }
    },
    login: function () {
      this.loginShow = true;
    },
    get: function (id, type) {
      let that = this;
      let vc = that.$route.query.vc;
      let url = "";
      if (vc != null && vc != undefined && vc != "" && vc !="0") {
        url = "/vcBggBulletinMain/getVcBulletinContent.do";
      } else {
        url = "/bulletin/get.do";
      }
      that.$http.get(that.base + url, {
        params: {
          id: id,
          type: type
        }
      }).then(function (data) {
        that.notice = data.data.result;
        that.$nextTick(() => {
          //渲染以后获取高度
          //元素高度
          let childContentHeight = document.getElementById("child_content").offsetHeight;
          if (childContentHeight <= 700) {
            //头部高度
            that.topHeight = 300;
          }
          //设置遮罩的高度
          that.loginHeight = (childContentHeight - that.topHeight) + "px";
        })
      }).catch(function (error) {
        console.log(error)
      })
    },
    getCategory: function (id) {
      let that = this;
      let cId = id + "";
      if (cId != null && cId != '' && cId.length > 0) {
        that.$http.get(this.base + "/promotion/get.do", {
          params: {
            'id': cId
          }
        }).then((data) => {
          that.dataObj = data.data.result;
          let dataRes = data.data.result;
          that.logoUrl = JSON.parse(dataRes.logoUrl)[0].url;
        })
      }
    },
    //判断用户是否登入
    checkUser: function (uuid) {
      let that = this;
      getUserInfo(that.member).then(function (res) {
        that.userInfo = res.data.data;
        if (that.userInfo.isLogin =='1') {
          that["userLoginFlag"] = true;
        } else {
          that["userLoginFlag"] = false;
        }
      }).catch(function (err) {
        console.log(err);
      });

    },
    //从cookie获取值
    getCookie: function (name) {
      let that = this;
      let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if (arr = document.cookie.match(reg)) {
        return (arr[2]);
      } else
        return null;
    },
    isAllowCheck() {
      let that = this;
      let formType = this.$route.query.formType;
      let contentId = this.$route.query.contentId;
      //检查用户是否登录
      let uuid = this.getCookie("uuid");
      if (that.$route.query.vc != null && that.$route.query.vc != undefined && that.$route.query.vc != "" && that.$route.query.vc != "0") {
        //采购中心，不做限制
        that.isAllow = true;
        if (that.isAllow) {
          that.showHeight = 'auto';
        }
        that.isVc =true;
      }else {
        let that = this;
        getUserInfo(that.member).then(function (res) {
          that.userInfo = res.data.data;
          if (that.userInfo.isLogin == '1') {
            that["userLoginFlag"] = true;
            getUserInfo(that.member).then(function (res) {
              that.userInfo = res.data.data;
              if ((formType == '10' || formType == "2010" || formType == "4010" || formType == "9010") && that.userLoginFlag) {
                //公开的项目,必须登录后才能查看
                that.isAllow = true;
                if (that.isAllow){
                  that.showHeight = 'auto';
                }
              } else if ((formType == '20' || formType == "2020" || formType == "4020" || formType == "9020") && that.userLoginFlag) {
                //是邀请项目，并且已经登录
                that.$http.get(that.base + "/bulletin/checkUserAllow.do", {
                  params: {
                    companyId: that.userInfo.companyId,
                    contentId:contentId,
                    type: that.$route.query.type,
                  }
                }).then(function (resData) {
                  if (resData.data.result) {
                    //有权限
                    that.isAllow = true;
                    that.showHeight = 'auto';
                  }
                }).catch(function (err2) {
                  console.log(err2);
                })
              }
            }).catch(function (err) {
              console.log(err);
            });
          }else {
            that["userLoginFlag"] = false;
          }
        }).catch(function (err) {
          console.log(err);
        });
      }
    },
    getConfigs: function (id) {
      let that = this;
      that.$http.get(that.base + "/content/getCall.do", {
        params: {
          id: id
        }
      }).then(function (res) {
        let data = res.data.result;
        if (data) {
          that.configForm = data;
          let userNames = that.configForm.userName.split("/");
          let phones = that.configForm.phone.split("/");
          that.configForm['userNames'] = userNames;
          that.configForm['phones'] = phones;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    getCompany: function (promotionId, entityName) {
      let that = this;
      that.promotion = promotionId;
      that.$http.get(that.base + "/promotion/get.do", {
        params: {
          id: that.promotion
        }
      }).then((da) => {
        that[entityName] = da.data.result;
        that.$nextTick(()=>{
          document.title = that.company.title;
        })
      });
    },
    setHeadHeight: function () {
      let head = document.getElementById("zone-head").offsetHeight;
      document.getElementById("zone-head-placeholder").style.height = head + "px";
    }
  }, created() {
    this.headCompanyId = this.$route.query.companyId;
    this.headId = this.$route.query.id;
    this.getCategory(this.$route.query.companyId);
    this.headLogo = this.$route.query.headLogo;
    this.get(this.$route.query.contentId, this.$route.query.type);
    this.checkUser(this.getCookie("uuid"));
    if (this.$route.query.id == '2540'){
      this.buyerTitle="采购公告";
    }else {
      this.buyerTitle="招采公告";
    }
    window.addEventListener('resize', () => {
      this.isAllowCheck();
      //屏幕大小变化的方法
    }, true);
    this.getCompany(this.$route.query.companyId, "company");
    /*初始化卡片*/
    this.getConfigs(203);
    this.isAllowCheck();

  }, watch: {
    loginShow: {
      deep: true,    // 这句重要
      handler(val) {
        this.isAllowCheck();
      }
    }
  },
  mounted() {
    this.setHeadHeight();
    window.addEventListener('resize', () => {
      this.setHeadHeight();
    }, true)
  }
}
</script>
<style scoped lang="css" src="../../assets/commoon/css/common.css"></style>

<style scoped>
.notice-left {
  width: 100% !important;
}

.logo-image {
  width: 400px;
  height: 60px;
  margin: 2px 4px 1px 4px;
}

.cms-header-content {
  background: #FFFFFF;
  width: 100%;
  height: 79px;
  margin: 0 auto;
  padding-top: 5px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .cms-header-content {
    background: #FFFFFF;
    width: 100%;
    height: 83px;
    margin: 0 auto;
    padding-top: 10px;
  }

  .logo-image {
    width: 334px;
    height: 63px;
    margin: 2px 4px 1px 4px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .cms-header-content {
    background: #FFFFFF;
    width: 100%;
    height: 61px;
    margin: 0 auto;
    padding-top: 10px;
  }

  .logo-image {
    width: 247px;
    height: 46px;
    margin: 2px 4px 1px 4px;
  }
}

@media (min-width: 576px ) and (max-width: 768px) {

  .cms-header-content {
    background: #FFFFFF;
    width: 100%;
    height: 61px;
    margin: 0 auto;
    padding-top: 10px;
  }

  .logo-image {
    width: 247px;
    height: 46px;
    margin: 2px 4px 1px 4px;
  }
}
</style>
