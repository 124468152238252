<template>
  <div id="specialized-new-footer" class="left">
    <div class="cms-container specialized-new-footer-content">
      <div class="w-100 h-100">
        <el-row>
          <el-col :span="10">
            <div class="footer-title">
              <img src="../../assets/image/pc/hyc_logo.png" class="footer-title-img">
              <span class="footer-title-span " >湖南华菱电子商务有限公司<span style="font-size: small">(技术支持单位)</span> </span>
            </div>
            <div class="footer-cms-content">
              <span class="footer-cms-name">技术支持电话：400-800-7255</span>
              <br>
              <span class="footer-cms-name">联系地址：湖南省长沙市天心区湘府西路222号华菱园商务楼</span>
            </div>
            <!-- <div class="footer-cms-content">
              <div class="footer-cms-img left cms-ssm-hidden">
                <img src="../../assets/image/pc/user.png" class="w-100 h-100" alt="">
              </div>
              <div class="left">
                <p>
                  <span class="footer-cms-name" v-if="configForm.userNames">{{ configForm.userNames[0] }}</span>
                  <span class="footer-cms-name" v-if="configForm.phones">{{ configForm.phones[0] }}</span>
                </p>
                <p>
                  <span class="footer-cms-name" v-if="configForm.userNames">{{ configForm.userNames[1] }}</span>
                  <span class="footer-cms-name" v-if="configForm.phones">{{ configForm.phones[1] }}</span>
                </p>
              </div>
            </div> -->
          </el-col>
          <el-col :span="4">&nbsp;</el-col>
          <el-col :span="10">
            <div class="footer-title">
              <a href="http://www.smehn.cn/" style="color: #535353" target="_blank" v-if="company.companyId =='2706'">
                <img src="../../assets/image/pc/icon_company1.png" class="footer-title-img">
                <span class="footer-title-span">湖南省中小企业公共服务平台</span>
              </a>
              <a :href="company.linkUrl" style="color: #535353" target="_blank" v-if="company.companyId !='2706'">
                <img v-if="company.companyId =='5245'" src="../../assets/image/pc/xjxq.png" class="footer-title-img">
                <img v-else-if="company.companyId =='5246'" src="../../assets/image/pc/csjkq.png"
                     class="footer-title-img">
                <img v-else src="../../assets/image/pc/icon_company1.png" class="footer-title-img">
                <span class="footer-title-span " >{{ company.title }}</span>
              </a>
            </div>
            <div class="footer-cms-content">
              <div class="left" v-if="company.companyId=='2706'">
                <p>
                  <img src="../../assets/image/pc/i_kefu.svg" class="footer-cms-server-img" alt="">
                  <span class="footer-cms-name ">服务热线</span>
                  <span class="footer-cms-name">96871</span>
                </p>
                <p>
                  <img src="../../assets/image/pc/i_shijian.svg" class="footer-cms-server-img" alt="">
                    <span class="footer-cms-name ">工作时间</span>
                  <span class="footer-cms-name">{{company.keyword}}</span>
                </p>
              </div>

              <div class="left" v-if="company.companyId !='2706' && company.newVersionZone =='1'">
                <p>
                  <span class="footer-cms-name">{{ company.keyword }}</span>
                </p>
                <p>
                  <span class="footer-cms-name">{{company.description}}</span>
                </p>
              </div>
              <div class="left footer-cms-name"  v-if="company.companyId !='2706' && company.newVersionZone !='1'" v-html="company.contactText">
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "specialized-new-footer", props: ["configForm","company"]
}
</script>

<style scoped>
.footer-cms-img {
  width: 45px;
  height: 45px;
  margin: 10px 20px 10px 0px;
}

.footer-cms-name {
  margin-right: 10px;
  font-size: 1.4rem;
}

.footer-cms-server-img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.footer-title {
  font-size: 1.7rem;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color:#535353 ;
  line-height: 60px;
  /*height: 50px;*/
  border-bottom: 1px dashed #999999;
  margin-bottom: 20px;
}

.footer-title-img {
  width: 25px;
  height: 25px;
}

.footer-title-span {
  margin-left: 10px;
}

#specialized-new-footer {
  width: 100%;
  background: white;
  color: #0C3256;
}

.specialized-new-footer-content {
  padding: 0px 50px 0px 50px;
}

@media screen and (max-width: 768px) {
  .footer-title-img {
    margin-right: 10px;
  }

  .specialized-new-footer-content {
    padding: 20px;
  }

  .footer-title-span {
    font-size: 1.3rem;
  }

  .footer-title {
    margin-bottom: 10px;
    line-height: 30px;
  }

  .footer-cms-name {
    font-size: 1rem;
  }

  footer-cms-server-img {
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .footer-cms-name {
    font-size: 1.2rem;
  }

  .specialized-new-footer-content {
    padding: 0px 30px 0px 30px;
  }
}
</style>
